import { t } from "i18n:astro";
import ExpandableSection from "../../../components/layout/ExpandableSection/ExpandableSection";
import { ExpandableId } from "../../../data/expandable";
import { ElementTheme } from "../../../types/theme";

const ValueCreation = () => {
  return (
    <>
      <ExpandableSection
        theme={ElementTheme.LIGHT}
        id={ExpandableId.CREATION_1}
        title={t("home:value-creation.building-networks.title")}
      >
        <p>{t("home:value-creation.building-networks.content")}</p>
      </ExpandableSection>
      <ExpandableSection
        theme={ElementTheme.LIGHT}
        id={ExpandableId.CREATION_2}
        title={t("home:value-creation.accessing-partners.title")}
      >
        <p>{t("home:value-creation.accessing-partners.content")}</p>
      </ExpandableSection>
      <ExpandableSection
        theme={ElementTheme.LIGHT}
        id={ExpandableId.CREATION_3}
        title={t("home:value-creation.market-knowledge.title")}
      >
        <p>{t("home:value-creation.market-knowledge.content")}</p>
      </ExpandableSection>
      <ExpandableSection
        theme={ElementTheme.LIGHT}
        id={ExpandableId.CREATION_4}
        title={t("home:value-creation.top-line-growth.title")}
      >
        <p>{t("home:value-creation.top-line-growth.content")}</p>
      </ExpandableSection>
      <ExpandableSection
        theme={ElementTheme.LIGHT}
        id={ExpandableId.CREATION_5}
        title={t("home:value-creation.improving-operations.title")}
      >
        <p>{t("home:value-creation.improving-operations.content")}</p>
      </ExpandableSection>
    </>
  );
};

export default ValueCreation;
